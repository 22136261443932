@use "./colorsFonts.scss";
@forward "./main_arrows/arrow.scss";
@forward "./main_arrows/upperArrow.scss";
.mainImg {
  display: flex;
  align-items: center;
  background-color: black;
  background: url(../images/warp.png);
  background-position: top center;
  height: 100vh;
}

.mainPhotoDiv {
  overflow: hidden;
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 820px) {
    flex-wrap: wrap;
    margin: 40px 20px 40px 20px;
  }

  @media screen and (min-width: 821px) {
    margin: 80px 20px 80px 20px;
  }

  section {
    flex-shrink: 1;
    @media screen and (min-width: 821px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 820px) {
      margin-left: 10px;
    }
    h2 {
      @media screen and (min-width: 821px) {
        font-size: 40px;
      }
      @media screen and (max-width: 820px) {
        font-size: 30px;
      }
    }
    h3 {
      color: colorsFonts.$red;
      @media screen and (max-width: 820px) {
        font-size: 16px;
      }
    }
    p {
      @media screen and (min-width: 821px) {
        line-height: 1.3;
      }
    }
    * {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .mainPhotos {
    
    @media screen and (min-width: 821px) {
      margin-right: 20px;
      margin-left: 30px;
      width: 50vw; 
      //max-height: 40vw;
    }
    @media screen and (max-width: 820px) {
      margin-right: 12px;
      margin-left: 18px;
      margin-top: 30px;
      width: 80vw;
      
    }
    align-self: flex-start;

    box-shadow: 15px 15px 20px black;
    border-radius: 20px;
  }
  .placeholder {
    overflow-y: clip;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    border-radius: 20px;
    height: 40vw;
    @media screen and (min-width: 820px) {
     
    }
    @media screen and (max-width: 820px) {
      height: 90vw;
      order: 2;
    }
  }
}

.howWeWorks {
  background-color: colorsFonts.$background;
  margin: 10px 10px 10px 10px;
  padding: 10px 5px 10px 5px;
  * {
    text-align: center;
  }
  .workDiv {
    position: static;
    margin: 20px 10px 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colorsFonts.$ultra_white_background;
    padding: 30px 40px 30px 0px;
    border-radius: 30px;
    border: 2px solid colorsFonts.$red;
    div {
      position: relative;
      top: -50px;
      right: 0px;
      background-color: colorsFonts.$red;
      border-radius: 50%;
      width: 40px;
      height: 28px;
      padding-top: 12px;
      color: colorsFonts.$nav_font_color;
      box-shadow: 5px 5px 10px black;
      align-self: start;
    }
    p {
      width: 200px;
      font-weight: bold;
    }
  }
  #procedure {
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    display: flex;

    justify-content: space-around;
    margin: 40px 0px 10px 0px;
    padding: 20px 5px 10px 5px;
  }
}

@mixin theText {
  margin: auto;
  padding: 10px 10px 10px 10px;
  color: colorsFonts.$welcome_font;
  background-color: rgba($color: #000000, $alpha: 0.2);
  box-shadow: 15px 15px 20px black;
}
.theText {
  @include theText;
  b {
    font-size: 40px;
  }
  h1,
  b,
  p {
    display: block;
    text-align: center;
  }
}
.theText-enter {
  @include theText;
  position: relative;
  right: 100vw;
}

.theText-enter-active {
  @include theText;
  position: relative;
  right: 0px;
  transition: all 0.5s;
}

.theText-exit {
  @include theText;
  opacity: 1;
}
.theText-exit-active {
  @include theText;

  opacity: 0;
  transition: all 0.5s;
}

.theText-enter-done {
  @include theText;
}
