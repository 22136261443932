@use "./colorsFonts.scss";

footer {
    border-top: 2px solid colorsFonts.$red;
  display: flex;
  justify-content: space-around;
  align-items: center; 
  @include colorsFonts.smallFont;
  @media only screen and (min-width: 821px) {
    padding: 20px 15px 20px 15px;
  }
  @media screen and (max-width: 820px) {
    padding: 7px 10px 7px 10px;
    flex-wrap: wrap;
    column-gap: 7px;
    font-size: smaller;
  }

  background-color: colorsFonts.$ultra_white_background;

  img {
    position: relative;
    right: 20px;
    @media only screen and (min-width: 821px) {
      width: 150px;
    }
    @media screen and (max-width:820px) {
      width: 100px;
    }
  }
  .footP {
    padding-bottom: 20px;
    border-bottom: 2px solid colorsFonts.$red;
  }
}
