@use "./colorsFonts.scss";

#galeryH1{
  margin: 40px auto 30px auto;
  text-align: center;
}
#galeryH2{
  text-align: center;
  font-weight: normal;
  text-align: center;
  font-style: italic; 
  margin: 10px auto 40px auto;
}
#centralPhotoDiv {
  padding: 10px 20px 10px 20px;
  background-color: colorsFonts.$ultra_white_background;
}
@media only screen and (min-width: 821px) {
.threePhotosGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  width: 100%;
  height: 30vw;
  overflow: hidden;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 821px) {
    margin: 30px 0px 30px 0px;
    column-gap: 20px;
    row-gap: 10px;
  }
  @media screen and (max-width: 1000px) {
    margin: 20px 0px 20px 0px;
    column-gap: 10px;
    row-gap: 5px;
  }
}
.bigPhoto {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  overflow: hidden;
  background-color: colorsFonts.$white_background;
}
.bigPhoto2 {
  display: grid;
  grid-column: 3 / span 2;
  grid-row: 1 / span 2;
  overflow: hidden;
  order: 3;
  background-color: colorsFonts.$white_background;
}
.smallPhoto {
  overflow: hidden;
  grid-column: 3 / span 2;
  background-color: colorsFonts.$white_background;
}
.smallPhoto2 {

  overflow: hidden;
  grid-column: 1 / span 2;
  grid-row-start: -1 / span 1;
  background-color: colorsFonts.$white_background;
}}
@media only screen and (max-width: 821px)
{
  .threePhotosGrid {
    display: flex;
    flex-direction: column;
    width: 100%;
    *{
      margin-top: 10px;
      margin-bottom:10px;
      width: 100%;
      overflow: hidden;

    }
    .bigPhoto, .bigPhoto2{
      max-height: 300px;
    }
    .smallPhoto, .smallPhoto2{
      max-height: 170px;
    }
  }
}
.smallPhoto, .smallPhoto2, .bigPhoto, .bigPhoto2{
display: flex;
align-items: center;
}

.redButton {
  height: 50px;
  width: 250px;
  font-weight: bold;
  font-size: large;
  background-color: colorsFonts.$red;
  border-radius: 10px;
  border-color: transparent;
  color: colorsFonts.$nav_font_color;
  margin: 20px auto 20px auto;
  transition: all 500ms;
  @media screen and (min-width: 1000px) {
  &:hover {
    border: 3px inset black;
    box-shadow: 7px 7px 10px black inset;
    background-color: colorsFonts.$darkRed;
    color: darkgray;
    text-shadow: 2px 2px 5px black;
    border-bottom: none;
    border-right: none;
    transition: all 500ms;
  }}
}
