@use './colorsFonts.scss';

#loginDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    div{

        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
        img {
        width: 300px;
        max-height: 500px;
        object-fit: contain;
        margin-top: 30px;
        @media screen and (max-width: 1271px) {
            width:200px;
        }
    }
}
    

    h1 {
        text-align: center;
        margin: 20px 0px 20px 0px;
    }

    form {
        margin: 30px 0px 50px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1271px) {
            margin: 10px 0px 20px 0px;
            flex-direction: column;
        }
        label {
            margin: 10px 7px 10px 20px;
        }

        input[type=submit] {
            height: 35px;
            width: 120px;
            font-weight: bold;
            font-size: large;
            background-color: colorsFonts.$red;
            border-radius: 10px;
            border-color: transparent;
            color: colorsFonts.$nav_font_color;
            margin: 20px 40px 20px 40px;
            transition: all 500ms;

            @media screen and (min-width: 1000px) {
                &:hover {
                    border: 3px inset black;
                    box-shadow: 7px 7px 10px black inset;
                    background-color: colorsFonts.$darkRed;
                    color: darkgray;
                    text-shadow: 2px 2px 5px black;
                    border-bottom: none;
                    border-right: none;
                    transition: all 500ms;
                }
            }
        }
    }
}

.panelInfo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.removerDiv {
    display: flex;
    align-items: center;
    margin: 20px 20px 20px 20px;
    max-height: 600px;

    @media screen and (max-width: 1271px) {

        flex-direction: column;
        background-color: colorsFonts.$ultra_white_background;
        margin: 10px 10px 30px 10px;
    }

    overflow: hidden;
    justify-content: center;

    .containerRemoverDiv {
        height: 600px;
        flex-shrink: 1;
        width: 70vw;
        overflow: hidden;

        @media screen and (max-width: 1271px) {
            height: 250px;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            flex-shrink: 1;
        }
    }

    button {
        height: 50px;
        width: 250px;
        font-weight: bold;
        font-size: large;
        background-color: colorsFonts.$red;
        border-radius: 10px;
        border-color: transparent;
        color: colorsFonts.$nav_font_color;
        margin: 20px 40px 20px 40px;
        transition: all 500ms;

        @media screen and (max-width: 1271px) {
            width: 200px;
            margin: 10px 10px 30px 10px;
        }

        @media screen and (min-width: 1000px) {
            &:hover {
                border: 3px inset black;
                box-shadow: 7px 7px 10px black inset;
                background-color: colorsFonts.$darkRed;
                color: darkgray;
                text-shadow: 2px 2px 5px black;
                border-bottom: none;
                border-right: none;
                transition: all 500ms;
            }
        }
    }
}



.background {
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    .confirm {
        max-width: 80vw;
        max-height: 80vh;

        @media screen and (max-width: 1271px) {
            max-width: 90vw;
            max-height: 90vh;
        }

        opacity: 1;
        background-color: colorsFonts.$white_background;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: clip;

        @media screen and (min-width: 1271px) {

            h3 {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            img {
                margin: 20px 20px 20px 20px;
                max-height: 50vh;
            }
        }

        @media screen and (max-width: 1271px) {
            h3 {
                margin: 20px 10px 10px 10px;
                text-align: center;
            }

            img {
                margin: 20px 20px 20px 20px;
                max-height: 200px;
            }

            .divButton {
                display: flex;
                flex-direction: column-reverse;
            }

        }

        img {

            width: 50vw;
            object-fit: contain;
        }

        button {
            @media screen and (max-width: 1271px) {
                width: 250px;
            }

            height: 50px;
            width: 250px;
            font-weight: bold;
            font-size: large;
            background-color: colorsFonts.$red;
            border-radius: 10px;
            border-color: transparent;
            color: colorsFonts.$nav_font_color;
            margin: 20px 40px 20px 40px;
            transition: all 500ms;

            @media screen and (min-width: 1000px) {
                &:hover {
                    border: 3px inset black;
                    box-shadow: 7px 7px 10px black inset;
                    background-color: colorsFonts.$darkRed;
                    color: darkgray;
                    text-shadow: 2px 2px 5px black;
                    border-bottom: none;
                    border-right: none;
                    transition: all 500ms;
                }
            }

        }

    }
}

.addPhotoContainer {
    display: flex;
    align-items: center;
    margin: 20px 40px 20px 40px;
    height: 600px;

    @media screen and (max-width: 1271px) {
        height: 300px
    }

    overflow: hidden;
    justify-content: center;

    div {
        padding: 10px 10px 10px 10px;
        width: 90%;
        height: 100%;
        background-color: grey;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: clip;

        img {
           
            object-fit: contain;
            width: 90%;
            height: 90%;
        }
    }
}

.changePasswordBackground {
    display: flex;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: colorsFonts.$white_background;
        padding: 40px 40px 40px 40px;

        * {
            margin: 10px 10px 10px 10px;
        }

        label {
            margin-bottom: 5px;
            margin-top: 15px;
        }

        h2 {
            text-align: center;
        }

        div {
            display: flex;
            justify-content: space-between;

            button {
                flex-shrink: 1;
                height: 50px !important;
                width: 150px !important;
            }
        }

    }
}