@use '../colorsFonts.scss';

#upperArrowsParent{
    position: relative;
    top: 0;
  
    @media screen and (max-width: 821px) {
      height:150px;
  }
  @media screen and (min-width: 821px) {
  height:30px;}
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
      @media screen and (max-width: 821px) {
        flex-direction: column;
    }
    transform: translate(0,-50%);
  }
  .upperArrow{
    img{
      margin-right: 10px;
      transform: scale(80%);
    }
    flex-shrink: 1;
    justify-content: left;
    background-color: colorsFonts.$white_background;
    display: flex;
    align-items: center;
    padding: 20px 15px 20px 15px;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    width: 300px;;
    margin: 10px 10px 10px 10px;
  }
  
  .transUpperArrow-enter{
    opacity: 0;
  }
  .transUpperArrow-enter-active{
    opacity: 1;
    transition: all 1s;
  }
  .transUpperArrow-exit{
    position: absolute;
    bottom: 0vh;
    opacity: 1;
  } 
  .transUpperArrow-exit-active{
    position: absolute;
    bottom: 50vh;
    opacity: 0;
    transition: all 0.5s;
  }
  .transUpperArrow-exit-done{
    position: absolute;
    bottom: 50vh;
    opacity: 0;
    transition: all 0.5s;
  }